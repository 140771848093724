<template>
  <div>
    <div class="a">
      我们发了
      <span class="value"> 74875 </span>
      段 语音
    </div>

    <div class="b">

    </div>
    <div class="e">
          相当于连续不断对话了
          <span class="value"> 13 </span>
          小时&nbsp;
        </div>
    <div class="d">如果能统计上语音电话的时间<br />肯定会更多！<br />随机语音记录播放器正在开发中<br />听听我们都说些什么吧~</div>
  </div>
</template>

<script>
import data from "@/data.json";
export default {
  data() {
    return {
      ...data,
    };
  },
};
</script>

<style scoped>
.a {
  font-size: 5vw;
  padding-top: 15vh;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: .5s;
  animation-fill-mode: backwards;
}
.b{
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}
.c{
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: backwards;
}
.d {
  padding-top: 10vh;
  font-size: 5vw;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 2.5s;
  animation-fill-mode: backwards;
}

</style>