<template>
  <div>
    <div class="a">
      四年来我们一共发了
      <span class="value"> 219553 </span>
      条消息
    </div>
    <div class="e">
      有
      <span class="value"> 170 </span>
      万字&nbsp; 相当于三国演义+水浒传的总和
    </div>
    <div class="b">

    </div>
    <div class="c">
      <div >
        我给你发了<span class="value"> 107万 </span>字
          </div>
          <div >
          你打了<span class="value"> 63 万</span>字
                    </div>
    </div>
    <div class="d">我打了那么多字<br />都给你说了什么呢？</div>
  </div>
</template>

<script>
import data from "@/data.json";
export default {
  data() {
    return {
      ...data,
    };
  },
};
</script>

<style scoped>
.a {
  font-size: 5vw;
  padding-top: 15vh;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: .5s;
  animation-fill-mode: backwards;
}
.b{
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}
.c{
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: backwards;
}
.d {
  padding-top: 10vh;
  font-size: 5vw;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 2.5s;
  animation-fill-mode: backwards;
}

</style>