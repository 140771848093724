<template>
  <div>
    <div style="padding-top: 15vh" class="a">
      这 1556 天里

      <span style="font-size: 5vw" class="value"> 可以领取了你的红包 </span>
      出现了
      <span class="value"> 260 次</span>
    </div>
    <div class="b">
      <img src="@/assets/images/hb.png" />
    </div>
  </div>
</template>

<script>
import { mostWord } from "@/data.json";
export default {
  data() {
    return {
      ...mostWord,
    };
  },
};
</script>

<style scoped>
.a{
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}
.b img {
  width: 100%;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}
</style>