<template>
  <div>
    <div style="padding-top: 15vh" class="a">
          除了 emoji，我们还发了
                <span class="value"> 6755 </span>
                个表情包和s
                <span class="value"> 19943 </span> 张图片
        </div>
    <div class="b">
      <img src="@/assets/images/dong.gif" />
    </div>
  </div>
</template>

<script>
import { mostWord } from "@/data.json";
export default {
  data() {
    return {
      ...mostWord,
    };
  },
};
</script>

<style scoped>
.a{
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}
.b img {
  width: 100%;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}
</style>